import './ErrorPage.scss'
import { config, ErrorPage as ErrorPageWC } from '@clout-team/web-components'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import { isInnerIframe } from '../../shared/lib/functions'
import { useLoginActions } from '../../state/slices/login'
import { Cookies } from 'react-cookie'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { getIsHasProfiles } from '../../state/selectors/getIsHasProfiles'
import { LenzaLogoError } from '@clout-team/icons/react'

const cookies = new Cookies()

export const ErrorPage = () => {
  const loginActions = useLoginActions()
  const isHasProfiles = useAppSelector(getIsHasProfiles)

  if (config.IS_MOBILE) {
    const isHasBackButton = isInnerIframe() && cookies.get('token')

    const onBack = () => {
      if (isHasProfiles) {
        loginActions.setStep('WORKSPACE')
      } else {
        loginActions.setStep('PROMO')
      }
    }

    return (
      <div className='error-page'>
        <MobileHeader onClose={isHasBackButton ? onBack : undefined} />
        <LenzaLogoError className='error-page_icon' />
        <ErrorPageWC />
      </div>
    )
  }

  return (
    <div className='error-page'>
      <LenzaLogoError className='error-page_icon' />
      <ErrorPageWC />
    </div>
  )
}
