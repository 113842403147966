import { Mention } from '@clout-team/icons/react'
import { Button, config, Input, Layout, Loading } from '@clout-team/web-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { checkDomainIsValid, fillCompanyProfile } from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { useLoginActions } from '../../state/slices/login'
import Heading from '../../shared/ui/Heading/Heading'
import './Domain.scss'
import { ReactComponent as SuccessSign } from '../../assets/images/successSIgn.svg'
import { ReactComponent as CancelIcon } from '../../assets/images/cancelIcon.svg'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import { useTranslation } from 'react-i18next'
import { WrapperMobilePage } from '../../shared/ui/WrapperMobilePage/WrapperMobilePage'
import { debounce } from '../../shared/lib/debounce'
import { checkToken } from '../../shared/lib/functions'
import { getIsHasProfiles } from '../../state/selectors/getIsHasProfiles'

const re = /^[a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]+$/

const Domain = () => {
  const { t } = useTranslation()
  const loginActions = useLoginActions()

  const [value, setValue] = useState<string>('')
  const isDomainLoading = useAppSelector((state) => state.login.is_domain_loading)
  const dispatch = useDispatch()
  const regHash = useAppSelector((state) => state.login.hash_registration)
  const isDomainFree = useAppSelector((state) => state.login.is_domain_free)

  const isHasProfiles = useAppSelector(getIsHasProfiles)

  const [error, setError] = useState<string>('')

  const checkError = (str: string) => {
    if (str.length >= 1 && str.length < 3) {
      setError(t('auth.new.domain.input_count_symbols_error'))
    } else if (str.length >= 3 && !re.test(str)) {
      setError(t('auth.new.domain.input_symbols_error'))
    }
  }

  const onDebounceCheckDomainIsValid = useCallback(
    debounce((value: string, regHash: string) => {
      if (!value) return

      if (
        (value.length >= 1 && value.length < 3) ||
        (value.length >= 3 && !re.test(value))
      ) {
        checkError(value)
        return
      }
      loginActions.setIsDomainLoading(true)
      dispatch(checkDomainIsValid({ hash_registration: regHash, domain: value }))
    }, 500),
    [regHash],
  )

  useEffect(() => {
    if (isDomainFree === false) {
      setError(t('auth.new.domain.input_error'))
    }
  }, [isDomainFree])

  useEffect(() => {
    if (!isDomainLoading) {
      const input = document.getElementById('domain-company')
      input?.focus()
    }
  }, [isDomainLoading])

  const onChangeDomain = (e: any) => {
    loginActions.setCompanyDomainStatus(null)
    const val: string = e.target.value.toLocaleLowerCase().trim()
    setValue(val)
    setError('')
    onDebounceCheckDomainIsValid(val, regHash)
  }

  const onContinueHandler = () => {
    if (isDomainFree && !isDomainLoading && value.length > 2 && regHash) {
      dispatch(
        fillCompanyProfile({
          domain: value,
          hash_registration: regHash,
          lang_id: 'ru',
        }),
      )

      loginActions.setCompanyName(value)
      loginActions.setStep('PROFILE')
    }
  }

  const onIconClickHandler = () => {
    setValue('')
    loginActions.setCompanyDomainStatus(null)
  }

  const handleBlur = () => {
    checkError(value)
  }

  const renderInputIcon = useMemo(() => {
    if (isDomainLoading)
      return (
        <div className={'d_loader_wrapper'}>
          <Loading size='sm' isNewDesign className={'d_loader'} />
        </div>
      )

    if (isDomainFree) return <SuccessSign className={'d_success_icon'} />

    if (!isDomainFree && error)
      return <CancelIcon onClick={onIconClickHandler} className={'d_cancel_icon'} />

    return <></>
  }, [error, isDomainFree, isDomainLoading])

  useEffect(() => {
    loginActions.setCompanyDomainStatus(null)
    return () => {
      loginActions.setCompanyDomainStatus(null)
    }
  }, [])

  if (config.IS_MOBILE) {
    const onBackToPage = () => {
      if (isHasProfiles) {
        loginActions.setStep('WORKSPACE')
      } else {
        loginActions.setStep('START')
      }
    }

    return (
      <WrapperMobilePage className={'d_domain'}>
        <MobileHeader
          title={t('domain.title')}
          onClose={checkToken() ? onBackToPage : undefined}
        />

        <div className='d_body'>
          <Heading
            className={'d_body_text'}
            align={'left'}
            description={t('domain.label')}
          />
          <Input
            id={'domain-company'}
            capitalizeValue={false}
            focus
            maxLength={24}
            value={value}
            className={'inp_auth_input'}
            label={
              <span className='inp_auth_input_label'>
                <b>{t('domain.company-name')}</b> (
                {t('domain.company-name.only-en-symbols')})
              </span>
            }
            placeholder={t('auth.new.domain.input_placeholder')}
            iconLeft={<Mention />}
            onChange={onChangeDomain}
            iconRight={renderInputIcon}
            onEnterPress={onContinueHandler}
            errorMessage={error}
            validate
            onBlur={handleBlur}
            valid={isDomainFree !== null && isDomainFree}
          />
        </div>
        <footer className='d_footer'>
          <ButtonUI
            size={'xlg'}
            disabled={
              error.length > 0 ||
              value.length === 0 ||
              (value.length >= 1 && value.length < 3) ||
              (value.length >= 3 && !re.test(value)) ||
              !isDomainFree
            }
            fullWidth
            onClick={onContinueHandler}
          >
            {t('auth.new.company_banner.btn')}
          </ButtonUI>
        </footer>
      </WrapperMobilePage>
    )
  }

  return (
    <div className={'d_domain'}>
      <Heading
        className='d_domain_header'
        title={t('domain.label.title')}
        description={t('domain.label.description')}
      />
      <div className={'d_domain__wrapper'}>
        <Layout gap={'lg'} vertical={true}>
          <Input
            id={'domain-company'}
            capitalizeValue={false}
            focus
            maxLength={24}
            value={value}
            className={'inp_auth_input'}
            label={t('auth.new.domain.input_title_second')}
            placeholder={t('domain.label.title')}
            // pattern="[a-z0-9\-_]+$"
            // description={t('auth.new.domain.input_description')}
            iconLeft={<Mention />}
            onChange={onChangeDomain}
            iconRight={renderInputIcon}
            onEnterPress={onContinueHandler}
            errorMessage={error}
            validate
            onBlur={handleBlur}
            valid={isDomainFree !== null && isDomainFree}
            disabled={isDomainLoading}
          />
        </Layout>

        <Button
          size={'lg'}
          disabled={
            error.length > 0 ||
            value.length === 0 ||
            (value.length >= 1 && value.length < 3) ||
            (value.length >= 3 && !re.test(value)) ||
            !isDomainFree
          }
          fullWidth
          onClick={onContinueHandler}
        >
          {t('auth.new.company_banner.btn')}
        </Button>
      </div>
    </div>
  )
}

export default Domain
