import { Dialog } from '@clout-team/web-components'
import { ChevronLeft } from '@clout-team/icons/react'

const DialogAgreement = (props: {
  L: (s: string) => string,
  onClose: () => void
}) => {
  const { L, onClose } = props

  return (
    <Dialog
      className={'dialog-auth-privacy'}
      closeOnClickOutside={false}
      dialogTitle={{
        title: (
        <div className='dialog-auth-privacy_title'>
          <ChevronLeft onClick={onClose}/>
          <p className='component-dialog-title-title-text'>{L('auth.privacy.user.agree')}</p>
        </div>
        ),
        varOnlyTitle: false,
      }}
      onClose={onClose}
      closeOnEsc
      size={'lg'}
      isScrollContent
    >
      <strong><p style={{ margin: 'margin: 20px 0px 20px' }}>{L('auth.privacy.user.agree_subtitle')?.replace('{_companyName_}', 'LENZA')}</p></strong>
      {/* {br} - перевод строки; {str} - Жирное начертание; {ul}/{ol} - начало списка/ {li}/{/li} - начало и конец элемента списка */}
      {L('auth.privacy.user.agree_text').split('{br}').map((p, i) => {
        return p.startsWith('{str}') ? (
            <strong key={i}><p>{p.replace('{str}', '')}</p></strong>
        ) : (
          ~p.search(/^{[uo]l}/) ? (
            <p key={i}>{
              p.startsWith('{ul}') ? (
                <ul>{p.replace('{ul}', '').split('{li}').map((li) => {
                  if (li.length) {
                    return li.endsWith('{/li}') ? <p><li>{li.replace('{/li}', '')}</li></p> : li
                  }
                })}</ul>
              ) : (
                <ol>{p.replace('{ol}', '').split('{li}').map((li) => {
                  if (li.length) {
                    return li.endsWith('{/li}') ? <p><li>{li.replace('{/li}', '')}</li></p> : li
                  }
                })}</ol>
              )
            }</p>
          ) : (
          <p key={i}>{p}</p>
          )
        )  
      })}
    </Dialog>
  )
}

export default DialogAgreement
