import { Button, InviteUsersInCompany, SuccessInviteModal } from '@clout-team/web-components'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
  createInviteLink,
  inviteAdmins,
  inviteUsers,
  skipInviteUsers,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { binarySearch } from '../../shared/const/emails'
import { useLoginActions } from '../../state/slices/login'
import Check from './Check/Check'
import CopyLink from './CopyLink/CopyLink'
import './InviteUsers.scss'
import { getDomainFromEmail, isCorpEmail } from '../../shared/lib/functions'
import { isMobile } from '@clout-team/helpers'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { useTranslation } from 'react-i18next'
import Heading from '../../shared/ui/Heading/Heading'

export const InviteUsersWeb: React.FC = () => {
  const { t } = useTranslation()

  const { setCorpDomains } = useLoginActions()

  const [isSkip, setIsSkip] = useState(false)//false
  const [isSuccess, setIsSuccess] = useState<boolean>(false)//false
  const [items, setItems] = useState<any[]>([])
  const [validatedItems, setValidatedItems] = useState<number>(0)//0
  const dispatch = useAppDispatch()
  const hashReg = useAppSelector((state) => state.login.hash_registration)//'xqdt-GN96-Pd29-U9N1'
  const email = useAppSelector((state) => state.login.mail)//'v.ilin@lenzaos.com'
  const inviteLink = useAppSelector((state) => state.login.invite_link)//"https://auth.lenzaos.com?invitation=true&code=7a44abe8e84a4b3b981170ff52db0b1344c644e1&domain=555test&lang=ru"
  const isMyDomainCorp = useAppSelector((state) => state.login.is_my_domain_corp)//false
  const isDomainInvite = useAppSelector((state) => state.login.is_domain_invite)//false
  const corpDomains = useAppSelector((state) => state.login.corpDomains)//[]

  const [isInviteUsersMenuOpen, setInviteUsersMenuOpen] = useState(true)
  const [stepUserInvite, setStepUserInvite] = useState(0)

  const isValidate = (el: any) => el.isValid && !el.isExists

  const onSendInvites = async () => {
    debugger
    if (isSuccess) {
      await dispatch(inviteAdmins({ skip: true, hash_registration: hashReg }))
      const validItems = items.map((el) => {
        if (isValidate(el)) {
          return el.title
        }
        return
      })

      await dispatch(
        inviteUsers({ emails: [...validItems], skip: false, hash_registration: hashReg }),
      )
      return
    }

    if (!isSuccess && validatedItems > 0) {
      const validItems = items.map((el) => {
        if (isValidate(el)) {
          return getDomainFromEmail(el.title)
        }
        return
      })

      const uniqueItems = validItems
        .filter((element, index) => {
          return validItems.indexOf(element) === index
        })
        .filter((el) => {
          const res = binarySearch(el)
          return res === -1 && el !== undefined
        })

      setCorpDomains(uniqueItems)
      setIsSuccess(true)
      setStepUserInvite(1)
    }
  }

  const onSkip = async () => {
    debugger
    if (isSuccess) {
      setIsSuccess(false)
      setInviteUsersMenuOpen(true)
      return
    }

    if (!isSuccess) {
      await dispatch(inviteAdmins({ skip: true, hash_registration: hashReg }))
      await dispatch(skipInviteUsers({ hash_registration: hashReg }))
    }
  }

  useEffect(() => {
    dispatch(createInviteLink({ hash_registration: hashReg }))
  }, [])

  const handleContinueButton = async () => {
    debugger
    return false
  }

  const onSkipStepInviteUsers = () => {
    setStepUserInvite(2)
    setIsSkip(true)
  }

  const finishReg = () => {
    if (isSkip) {
      onSkip()
    } else {
      onSendInvites()
    }
  }

  if (stepUserInvite === 2) {
    if ((!email || !isCorpEmail(email) || isSuccess) && !corpDomains.length) {
      finishReg()
    }
    
    return (
      <div className='inu_check'>
        <Heading title={t('auth.new.invite.checkbox_domains')} className='inu_check_title' />
        <div className={'inu_invite__check_wrapper'}>
          {(email && isCorpEmail(email) && !isSuccess) ? (
            <Check isMyDomain corpDomains={[getDomainFromEmail(email)]} goToFinishReg={finishReg}/>
          ) : (
            <Check corpDomains={corpDomains} goToFinishReg={finishReg} />
          )}
        </div>
      </div>
    )
  }

  if (stepUserInvite === 1) {
    return (
      <SuccessInviteModal data={items} onClose={() => setStepUserInvite(2)} showIsValid />
    )
  }

  return (
    <div className={'inu_invite'}>
      <h2 className={'inu_invite__heading'}>
        {isSuccess
          ? `${t('auth.new.invite_users.your_invitation')?.replace('{__items__}', validatedItems?.toString())}`
          : `${t('auth.new.invite.title')?.replace(':', '')}${validatedItems > 0 ? ': ' + validatedItems : ''}`}
      </h2>

      {isSuccess && (
        <p className={'inu_invite__description'}>
          {t('auth.description.you_invited')?.replace(
            '{__members__}',
            ` ${validatedItems} ${t('auth.description.members')}`,
          )}
        </p>
      )}

      <div
        className={classNames('inu_invite__wrapper', {
          inu_invite__wrapper_success: isSuccess,
        })}
      >
        {isInviteUsersMenuOpen && (
          <InviteUsersInCompany
            version={1}
            isUserInvite={false}
            isSuccess={isSuccess}
            onChangeValidItems={(count: number) => {
              setValidatedItems(count)
            }}
            handleContinueButton={handleContinueButton}
            onModalClose={() => {
              if (isMobile()) setInviteUsersMenuOpen(false)
            }}
            onChangeItems={(val: any) => setItems(val)}
            hashRegistration={hashReg}
          />
        )}

        {!isSuccess && (<CopyLink copyTarget={inviteLink as string} />)}
      </div>

      <div className={'inu_invite__buttons'}>
        {isMyDomainCorp && validatedItems === 0 ? (
          <Button
            size={'lg'}
            className={'inu_invite__buttons_send'}
            fullWidth
            onClick={onSkipStepInviteUsers}
          >
            {t('auth.new.done')}
          </Button>
        ) : (
          <Button
            size={'lg'}
            disabled={validatedItems === 0}
            className={'inu_invite__buttons_send'}
            fullWidth
            onClick={onSendInvites}
          >
            {isSuccess ? t('auth.new.done') : t('auth.new.send')}
          </Button>
        )}

        {isDomainInvite ? null : (
          <p onClick={onSkipStepInviteUsers} className='inu_invite__link_skip'>
            {isSuccess ? t('auth.new.invite_more') : t('auth.new.invite_later')}
          </p>
        )}
      </div>
    </div>
  )
}
